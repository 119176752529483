.service-request-wrapper {
  background: #f2f6f8 !important;
}
.header-section {
  width: 100%;
  height: 125px;
  float: left;
  color: #2546b1;
}

.container-md {
  height: 500px;
  width: 103% !important;
  overflow-y: scroll;
  padding-right: 10px !important;
  padding-bottom: 10px;
  font-family: Damen-font !important;
}
.dateStyle{
  margin-top: 5px !important;
}
.service-request-wrapper {
  background: #e7e3e3;
  height: 150px;
  font-family: Damen-font !important;

  .title {
    padding: 0px;
    height: 95px;
    background: #f2f6f8 !important;
  }

  .title .welcome {
    font-size: 13pt;
    color: black;
    height: 60%;
    width: 100%;
    float: left;

    .user-box {
      float: left;
      font-size: 20pt;
      height: 100%;
      width: 50pt;

      img {
        height: 50px;
        border-radius: 35px;
        width: 50px;
      }
    }

    .vessel-name {
      float: left;
      font-size: 14pt;
      width: 70%;
      height: 50%;
    }

    .vessel-number {
      float: left;
      font-size: 9pt;
      height: 50%;
      width: 70%;
    }
  }

  .title .sub-header {
    padding-top: 8px;
    text-transform: uppercase;
    font-size: 13pt !important;
    height: 20px;
    float: left;
    width: 100%;
    color: #2546b1;
  }

  .service-request {
    float: left;
    width: 100%;
    margin-top: 10px;
    .service-request-details-chief-engineer {
      margin-right: -7px;
      margin-left: -35px;

      .row-details {
        border-bottom: 1px solid rgb(197, 197, 197);

        .general-description {
          text-align: left;
          padding-left: 5px;
          color: #011736;
          text-transform: capitalize;
        }

        .specific-details {
          width: 104%;
          height: 100%;
          padding-left: 5px;
          font-size: 10pt;
          padding-top: 5px;

          font-family: sans-serif;

          .request-timestamp {
            width: 37%;
            height: 100%;
            float: left;
            text-align: left;
            color: #bdbcbc;
          }

          .request-status {
            width: 63%;
            height: 100%;
            // float: left;
            float: right;
            // text-align: right;
            .pending {
              color: #ff7c52;
            }
            .approved {
              color: blue;
            }
            .cancel{
              color: #f02e2e;
            }
          }

          .request-status-progress {
            width: 50%;
            height: 100%;
            // float: left;
            float: right;
            // text-align: right;
            color: rgb(255, 123, 0);
          }
        }
      }

      .request-button {
        background: #747070;
        border: none;
        font-family: sans-serif;
        font-size: 11pt;
        border-radius: 2px;
        padding: 5px 10px;
        color: white;
        text-transform: uppercase;
      }
    }
    .p-tabview .p-tabview-nav li {
      width: 50%;
      float: left;
      color: #2546b1;
      padding-left: 31px;
    }

    .p-tabview .p-tabview-nav li :focus,
    .p-tabview .p-tabview-nav li :hover,
    .p-tabview .p-tabview-nav li:active {
      outline: none;
      border: none;
      text-decoration: none;
      color: #2546b1;
    }

    .p-tabview .p-tabview-nav .p-tabview-ink-bar {
      width: 197px;
      left: 0px;
    }

    .p-tabview-title {
      font-family: Damen-font !important;
    }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem white !important;
    }

    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background: transparent;
      border-color: #2546b1;
      color: #2546b1;
    }
    //   .p-tabview-panels{
    //     overflow: scroll !important;
    //     height: 470px !important;
    //   }

    .p-tabview .p-tabview-panels {
      padding: 0rem !important;
      padding-top: 0px !important;
    }

    .p-tabview-nav-container {
      width: 100%;
      //margin-left: -15px;
    }
  }

  .service-request-remote-access {
    float: left;
    padding: 5px;
    position: absolute;
    bottom: 2px;
    right: 10px;
    height: 150px;
    background: white;
    width: 100%;
    .request-access-button {
      padding: 10px;
      background-color: #ff7c52;
      border: none;
      margin-top: 10px;
      margin-right: 10px;
      border-radius: 18px;
      float: right;
      .lock-icon {
        font-size: 1.5em;
        padding-bottom: 5px;
      }
    }
  }

  .card {
    margin-left: -12px;
    float: left;
    width: 107%;
  }

  .p-paginator .p-component {
    //width: 100%;
    float: left;
  }

  .p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 2.5rem;
    height: 2.5rem;
  }

  .p-paginator .p-paginator-first {
    display: none;
  }

  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next {
    margin-left: -19px;
  }

  .p-paginator-next .p-paginator-element .p-link {
    margin-left: -11px;
  }

  .p-paginator-pages {
    margin-left: -14px;
  }

  .p-paginator-next .p-paginator-element {
    margin-left: -9px;
  }
  .p-paginator .p-paginator-last {
    display: none;
  }

  .p-paginator .p-dropdown {
    margin-left: 0;
    height: 3rem;
    margin-right: -25px;
  }
}

.no-record {
  margin-top: 200px;
  font-size: 13pt;
  font-family: "DAMEN-FONT";
  color: #4338ca;
}

.p-disabled {
  pointer-events: none !important;
}

.p-tabview-nav-link {
  background-color: transparent !important;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .dashboard {
    overflow: hidden !important;
  }

  .container-md {
    overflow-y: scroll !important;
    height: 80px !important;
    width: 100% !important;
  }

  .specific-details {
    width: 100% !important;
  }
}

//iphone SE
@media all and (device-width: 375px) and (device-height: 667px) {
  .p-tabview-panel {
    height: 335px !important;
    width: 99% !important;
    overflow: scroll !important;
  }

  .container-md {
    width: 102% !important;
    overflow:hidden !important;
  }
  .request-timestamp {
    width: 38% !important;
  }
  .request-status{
    width:62% !important;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0.74rem 1rem !important;
  }
}

@media all and (device-width: 360px) and (device-height: 740px) {
  .request-timestamp {
    width: 39% !important;
  }
  .request-status{
    width:61% !important;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0.74rem 1rem !important;
  }
}
.statusMsg{
  text-align: left;
    display: inline-block;
    width: 80%;
    
}
.requestStyle{
  // height: 0%;
  text-align: left;
    color: #2B8360;
}
