.service-details-wrapper {
  background: none;
  // height: 150px;

  .title {
    padding: 0px;
    height: 110px;
  }

  .title .welcome {
    font-size: 13pt;
    color: black;
    height: 85% !important;
    width: 100%;
    float: left;
    padding: 10px;
    background: white;
    border-radius: 2px;
    border: 1px solid #e5e2e2;
    margin-top: 10px;

    .user-box {
      float: left;
      font-size: 20pt;
      height: 100%;
      width: 60pt;
      text-align: center;
      padding-top: 6px;

      img {
        height: 50px;
        border-radius: 35px;
        width: 50px;
      }
    }
  }

  .title .request-header {
    float: left;
    height: 30px;
    width: 100%;
    color: #2546b1;
    text-align: left;
    padding-top: 10px;
  }

  .issue-description-wrapper-chief-engineer {
    font-family: arial;
    min-height: 200px;
    max-height: 230px;
    width: 100%;
    float: left;
    margin-bottom: 10px;
    .issue-container {
      width: 100%;
      margin: 0;

      .issue-description {
        width: 100%;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: bold;
        font-size: 12pt;
        float: left;
        width: 100%;
        height: 40px;
        text-transform: capitalize;
      }

      .part-specification {
        width: 100%;
        float: left;
        text-align: left;
        padding-top: 5px;
      }

      .issue-time-status {
        width: 100%;
        float: left;
        padding-top: 25px;
        font-size: 11pt;

        .issue-timestamp {
          width: 50%;
          float: left;
          text-align: left;
        }

        .issue-status-description {
          width: 50%;
          float: left;
          text-align: right;
          padding-right: 20px;
        }

        .pending {
          color: #ff7c52;
        }
        .approved {
          color: blue;
        }
      }

      .detailed-description {
        min-height: 41px;
        max-height: 159px;
        text-align: left;
        width: 100%;
        float: left;
        padding-top: 8px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e5e2e2;

        .description-header {
          font-weight: bold;
          color: #2546b1;
          height: 20px;
        }

        .description-details {
          font-size: 10pt;
          margin-top: 10px;
          min-height: 37px;
          max-height: 114px;
          inline-size: 360px;
          overflow-wrap: break-word;
        }

        .description-images {
          width: 100%;
          height: 50px;
          margin-top: -20px;
          img {
            padding-right: 20px;
          }
        }
      }
    }
  }

  .workflow-wrapper-chief-engineer {
    font-family: arial;
    float: left;
    padding-bottom: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 400px;
    min-height: 150px;
    .workflow-header {
      height: 30px;
      text-align: left;
      font-size: 13pt;
      font-weight: bold;
      color: #2546b1;
      padding-top: 10px;
    }

    .workflow-steps {
      height: 90px;
      width: 97%;
      margin-top: 10px;
      .step-summary {
        width: 100%;
        height: 100%;
        margin-top: 10px;

        .worflow-diagram-chief-engineer {
          height: 80px;
          float: left;
          width: 7%;
          padding-top: 10px;

          .workflow-round {
            height: 20px;
            width: 20px;
            border: 1px solid black;
            border-radius: 20px;
          }
          .workflow-round-completed {
            height: 10px;
            width: 10px;
            border: 1px solid green;
            border-radius: 10px;
            background-color: green;
            margin-top: 4px;
            margin-left: 4px;
          }

          .workflow-arrow {
            height: 43px;
            margin-left: 11px;
            margin-top: 7px;
            width: 100%;
            border-left: 1px solid black;
          }
        }

        .step-details {
          width: 93%;
          height: 29px;
          padding-top: 10px;
          float: left;

          .step-description {
            width: 51%;
            float: left;
            text-align: left;
            font-size: 10pt;
          }

          .step-timestamp {
            width: 49%;
            float: left;
            text-align: right;
            font-size: 10pt;
            color: #938e8e;
          }
        }

        .step-approval {
          width: 90%;
          height: 40px;
          font-size: 11pt;
          float: left;

          .step-approver-details {
            width: 69%;
            float: left;
            text-align: left;
          }

          .step-status {
            width: 19%;
            float: right;
          }
          .button {
            width: 31%;
            margin-top: 10px;
          }
          .pending-status {
            margin-right: -58px;
          }
          .pending-button {
            background-color: #ff7c52;
            color: white;
            border: none;
            padding: 0.2rem 0.5rem;
            width: 98px;
          }
          .pending {
            color: red;
          }
          .approved {
            color: blue;
          }
        }
      }
    }
  }

  .pending-steps-wrapper {
    width: 100%;
    height: 200px;
    font-family: arial;
    float: left;
    margin-top: 10px;

    .task-header {
      font-size: 13pt;
      font-weight: bold;
      text-align: left;
      width: 100%;
      height: 30px;
      color: #2546b1;
    }

    .task-list-body {
      height: calc(100% - 10px);
      width: 100%;

      .task-list-details {
        float: left;
        width: 100%;
        padding-bottom: 10px;

        .workflow-round {
          height: 20px;
          width: 20px;
          border: 1px solid black;
          border-radius: 20px;
          float: left;
        }

        .task-name {
          width: 80%;
          float: left;
          text-align: left;
          padding-left: 20px;
        }
      }
    }
  }
}

.active-workflow {
  padding: 1px 5px;
  box-shadow: 1px;
  box-shadow: 1px 3px 7px grey;
  font-weight: bold;
}

.access-approve-modal-chief-engineer {
  width: 100vw;
  height: 100vh;

  .modal-dialog {
    background: none !important;
    font-family: "DAMEN-FONT";
    font-weight: bold;
    color: #2546b1;
    .modal-header {
      border-bottom: none;
    }

    .access-request-header-chief-engineer {
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 14pt;
      font-family: arial;
      padding-left: 30px;
      color: #2546b1;
      font-family: "DAMEN-FONT";
    }

    .service-provider-details-wrapper {
      width: 100%;
      height: 92%;

      .service-provider-image {
        width: 100%;
        height: 80px;
        img {
          height: 75px;
          border-radius: 38px;
          width: 75px;
          border: 1px solid;
        }
      }

      .service-provider-name {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #80808069;
        line-height: 50px;
      }

      .service-provider-request-description {
        width: 100%;
        height: 100px;
        padding: 35px;
        padding-top: 20px;
        color: black;
      }

      .vessel-details {
        .welcome {
          font-size: 13pt;
          color: black;
          height: 85% !important;
          width: 100%;
          float: left;
          padding: 10px;
          background: white;
          border-radius: 2px;
          border-bottom: 1px solid #80808069;

          .user-box {
            float: left;
            font-size: 20pt;
            height: 100%;
            width: 60pt;
            text-align: center;
            padding-top: 2px;
            padding-left: 40px;

            img {
              height: 50px;
              border-radius: 35px;
              width: 50px;
            }
          }

          .vessel-name,
          .vessel-number {
            width: 80%;
          }
          .vessel-name{
            margin-left: 20px;
          }
        }
      }

      .service-request-reason {
        float: left;
        height: 90px;
        width: 100%;
        border-bottom: 1px solid #80808069;

        .reason-header {
          height: 50%;
          padding-top: 10px;
          width: 100%;
          text-align: left;
          padding-left: 15px;
        }

        .reason-description {
          width: 100%;
          text-align: left;
          padding-left: 16px;
          color: black;
        }
      }

      .service-requestedBy-details {
        float: left;
        width: 100%;
        height: 90px;
        line-height: 60px;
        border-bottom: 1px solid #80808069;

        .service-requestBy-label {
          width: 100%;
          height: 36%;
          float: left;
          text-align: left;
          padding-left: 15px;
        }

        .service-requestor-name {
          color: black;
          float: left;
          float: left;
          width: 100%;
          text-align: left;
          padding-left: 15px;
        }
      }

      .service-request-timestamp {
        width: 100%;
        height: 60px;
        float: left;
        width: 100%;
        line-height: 60px;
        font-size: 11pt;
        border-bottom: 1px solid #80808069;

        .service-requesttimestamp-label {
          height: 100%;
          width: 46%;
          float: left;
          font-size: 12pt;
          text-align: left;
          padding-left: 15px;
        }

        .service-request-timestamp {
          width: 53%;
          float: left;
          color: black;
        }
      }

      .service-request-duration {
        width: 100%;
        height: 60px;
        float: left;
        line-height: 60px;
        font-size: 11pt;
        border-bottom: 1px solid #80808069;

        .service-requestduration-label {
          height: 100%;
          width: 44%;
          float: left;
          font-size: 12pt;
          text-align: left;
          padding-left: 15px;
        }
        .service-duration-timestamp {
          width: 56%;
          float: left;
          color: black;
        }
      }
      .service-desired-duration {
        height: 60px;
        width: 100%;
        float: left;
        line-height: 60px;
        font-size: 12pt;
        border-bottom: 1px solid #80808069;
        .service-desired-label {
          width: 47%;
          float: left;
          text-align: left;
          padding-left: 15px;
        }
        .service-desired-timestamp {
          font-size: 11pt;
          width: 53%;
          float: left;
          color: black;
        }
      }
      .approval-denial-comments {
        float: left;
        width: 100%;
        height: 135px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .mb-0 {
        text-align: left;
        padding-left: 13px;
      }
      .approve-deny-button-group {
        float: left;
        width: 100%;
        height: 50px;
        padding-left: 14px;

        .deny-button {
          padding: 8px 33px;
          margin-right: 10px;
          color: black;
          background: white;
          border-radius: 2px;
          font-weight: lighter;
          font-family: arial;
          background: #ff7c52;
          border: none;
          padding: 9px 32px;
          color: white;
        }

        .approve-button {
          padding: 8px 15px;
          border-radius: 2px;
          font-weight: lighter;
          font-family: arial;
          background: #2546b1;
          color: white;
          font-weight: bold;
        }
      }
    }

    .modal-content {
      height: 98vh !important;
    }
  }
}

.adjust-height {
  height: 150px !important;
}

.p-toast {
  z-index: 1051 !important;
}

.user-icon{
  background-image: url('../../assets/user_icon.svg');
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px !important;
    left: calc(100% - 77%);
  }
}

@media only screen and (orientation: landscape) {
  .service-request-wrapper {
    width: 101% !important;
    .header-wrapper {
      width: 100% !important;
      height: 68px !important;
      .app-header {
        height: 0% !important;
        padding-top: 0px !important;
      }
      .service-request-header {
        padding-top: 25px !important;
      }
    }
    .p-tabview-nav-content {
      height: 51px !important;
    }
    .service-request-remote-access {
      height: 59px !important;
      width: 100% !important;
      background: white !important;
      border-top: 1px solid #8080802e !important;
      bottom: 3px !important;
      right: 0px !important;
      left: 0px !important;
      .card {
        border: none !important;
        margin-top: -4px !important;
        float: left !important;
        width: 50% !important;
      }
    }
    .p-paginator {
      padding: none !important;
    }
  }
  .modal-dialog .modal-content {
    overflow: scroll !important;
    .modal-body {
      height: 1000px !important;
      textarea {
        height: 100px !important;
        margin-bottom: 10px;
      }
    }
  }
  .no-record {
    height: 400px !important;
    // overflow: scroll !important;
    margin-top: 100px !important;
  }
  .dashboard {
    overflow: scroll !important;
  }

  .description-details {
    inline-size: 447px !important;
    overflow-wrap: break-word !important;
    min-height: 52px !important;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .service-details-wrapper {
    height: 100% !important;
    overflow: scroll !important;
  }

  .description-details {
    inline-size: 577px !important;
  }

  .issue-container {
    width: 91% !important;
    margin-left: 30px !important;
  }

  .workflow-wrapper-chief-engineer {
    margin-left: 30px !important;
  }

  .pending-steps-wrapper {
    margin-left: 30px !important;
    width: 91% !important;
  }
}

@media (min-width: 576px) and (orientation: landscape) {
  .modal-dialog {
    left: 15%;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .description-details {
    inline-size: 347px !important;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3.5) {
  .description-details {
    inline-size: 335px !important;
    margin-top: 0px !important;
  }

  .workflow-wrapper-chief-engineer {
    padding-top: 10px;
  }

  .step-description {
    width: 50% !important;
  }

  .step-timestamp {
    width: 50% !important;
    font-size: 9pt !important;
  }

  .step-summary {
    width: 101% !important;
  }

  .service-request-timestamp,
  .service-desired-timestamp {
    font-size: 10pt !important;
  }
}

@media only screen and (width: 412px) and (height: 915px) {
  .description-details {
    inline-size: 372px !important;
  }
}

@media only screen and (width: 412px) and (height: 732px) {
  .description-details {
    inline-size: 372px !important;
  }
}

@media only screen and (width: 414px) and (height: 896px) {
  .description-details {
    inline-size: 377px !important;
  }
}

@media only screen and (width: 820px) and (height: 1180px) {
  .description-details {
    inline-size: 445px !important;
  }
}

@media only screen and (width: 1180px) and (height: 820px) and (orientation: landscape) {
  .description-details {
    inline-size: 405px !important;
  }
  .container-md {
    overflow-y: scroll !important;
    height: 500px !important;
    width: 100% !important;
  }
}

@media only screen and (width: 768px) and (height: 1024px) {
  .description-details {
    inline-size: 445px !important;
  }
  .container-md {
    overflow-y: scroll !important;
    height: 500px !important;
    width: 100% !important;
  }
}

@media only screen and (width: 1024px) and (height: 768px) and (orientation: landscape) {
  .description-details {
    inline-size: 405px !important;
  }
  .container-md {
    overflow-y: scroll !important;
    height: 500px !important;
    width: 100% !important;
  }
}

@media all and (device-width: 375px) and (device-height: 667px) {
  .dashboard {
    overflow: scroll !important;
  }

  .workflow-wrapper-chief-engineer {
    overflow: hidden !important;
  }

  .step-description{
    width: 49% !important;
  }

  .step-timestamp{
    width: 51% !important;
  }

  .service-request-timestamp,.service-desired-timestamp,.service-duration-timestamp{
    font-size:10pt !important;
  }

  .service-requesttimestamp-label ,.service-desired-label,.service-requestBy-label,.reason-header,
  .service-requestduration-label,.form-label{
    font-size:11pt !important;

  }
  .access-approve-modal-chief-engineer .modal-dialog .modal-content{
    height:125vh !important;
  }
}
.cancel{
  color: #f02e2e;
}
.imoStyle{
  color: rgb(134, 134, 134);
  width: 200px !important;
  font-size: 14px;
}
.eqipStyle{
  margin-top: -3px;
  color: #2B8360 !important;
}
.equiText{
  margin-left: 20px;
}
.imoText{
  color: rgb(134, 134, 134);
  font-size: 14px; 
  margin-left: 7px;
}
